<template>
  <ion-page id="page-content">
    <ion-modal :is-open="showSort" @didDismiss="showSort = false" :initialBreakpoint="$store.state.deviceHeight < 700 ? 0.45 : 0.4" css-class="trans-sort-modal">
      <div class="modal-content trans-sort">
        <div class="title-box">
          <span class="close" @click="showSort = false"></span>
          <span class="font-18 main-color font-b">Select month</span>
          <span class="font-14 sub-color" @click="monthSelect">Apply</span>
        </div>
        <ul class="list-box">
          <li>
            <p class="font-16 label-color">This Month</p>
            <button class="check-btn" @click="monthPick = 0" :class="{ active: monthPick == 0 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past month</p>
            <button class="check-btn" @click="monthPick = 1" :class="{ active: monthPick == 1 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past 2 Months</p>
            <button class="check-btn" @click="monthPick = 2" :class="{ active: monthPick == 2 }"></button>
          </li>
          <li>
            <p class="font-16 label-color">Past 3 Months</p>
            <button class="check-btn" @click="monthPick = 3" :class="{ active: monthPick == 3 }"></button>
          </li>
        </ul>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true" :scroll-events="true" @ionScroll="logScrolling($event)">
      <topbar :bigtitle="true" :class="{ 'letter-ios-05': $store.state.deviceType == 1 }" title="eCoupon History" :showBack="true"></topbar>
      <!-- <ion-card
            class="slide-card"
            v-for="(item, i) in eCouponList"
            :key="i"
            @click="goDetail(item)"
          >
            <img :src="item.img" />
            <ion-card-header class="card-header">
              <ion-card-title class="eCouponTitle">{{
                item.name
              }}</ion-card-title>
              <div class="center-item">
                <ion-card-subtitle class="eCouponSubTitle"
                  >Valid Till
                  {{
                    formatDate(
                      item.redeemedDate,
                      $store.state.form.merchantDetail.dateFormat
                    )
                  }}</ion-card-subtitle
                >
              </div>
            </ion-card-header>
          </ion-card> -->
      <div class="transaction-page margin-top-160">
        <div class="sort-box">
          <img style="margin-right: 10px" src="../assets/icon-calendar.png" />
          <span @click="showSort = true" class="font-16 sub-color font-m"> Select month </span>
        </div>
        <ul class="list-box">
          <li v-for="(item, i) in eCouponList" :key="i">
            <div class="detail">
              <p class="font-18 main-color font-m Medium">{{ item.name }}</p>
              <p class="font-16 gery-color font-m">
                Date & Time : <br />
                {{ item.redeemedDate ? item.redeemedDate.replaceAll("-", "/") : item.redeemedDate }}
              </p>
              <p class="font-16 gery-color font-m Medium">Transaction id: {{ item.transNo }}</p>
            </div>
          </li>
        </ul>
        <ion-infinite-scroll @ionInfinite="loadData" threshold="100px" id="infinite-scroll" :disabled="isDisabled">
          <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more data..."> </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "eCouponHistory",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar, IonInfiniteScroll, IonInfiniteScrollContent },
  data() {
    return {
      showSort: false,
      monthPick: 0,
      monthList: ["January", "February", "March", "April", "May", "June", "July", "August", "September"],
      eCouponList: [],
      expiredCouponList: [],
      pageNum: 0,
      pageLimit: 20,
      isDisabled: false,
      tab: 1,
      startTime: this.$store.state.nowMonthStart,
      endTime: this.$store.state.nowMonthEnd,
      scrollTop: 0,
    };
  },
  methods: {
    async init(e) {
      const objdata = this.getAuthObj();
      objdata.couponId = this.$route.params.id;
      objdata.userCouponType = 2;
      objdata.pageNum = this.pageNum;
      objdata.pageLimit = this.pageLimit;
      objdata.startTime = this.startTime;
      objdata.endTime = this.endTime;
      objdata.apiUrl = this.apiConfig.listUserCoupon;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (this.pageNum == 0) {
        this.eCouponList = [];
      }
      data.data.result.forEach(item => {
        this.eCouponList.push(item);
      });
      if (e) {
        e.target.complete();
      }
      if (data.data.result.length < this.pageLimit) {
        this.isDisabled = true;
      }
    },
    async getExpired() {
      const objdata = this.getAuthObj();
      objdata.couponId = this.$route.params.id;
      objdata.userCouponType = 3;
      objdata.pageNum = this.pageNum;
      objdata.pageLimit = this.pageLimit;
      objdata.apiUrl = this.apiConfig.listUserCoupon;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      data.data.result.forEach(item => {
        this.expiredCouponList.push(item);
      });
      if (data.data.result.length < this.pageLimit) {
        this.isDisabled = true;
      }
    },
    logScrolling(e) {
      this.scrollTop = e.detail.scrollTop;
    },
    monthSelect() {
      this.pageNum = 0;
      this.isDisabled = false;
      switch (this.monthPick) {
        case 0:
          this.startTime = this.$store.state.nowMonthStart;
          this.endTime = this.$store.state.nowMonthEnd;
          break;
        case 1:
          this.startTime = this.$store.state.lastMonthStart;
          this.endTime = this.$store.state.lastMonthEnd;
          break;
        case 2:
          this.startTime = this.$store.state.pastMonthStart;
          this.endTime = this.$store.state.pastMonthEnd;
          break;
        case 3:
          this.startTime = this.$store.state.lPastMonthStart;
          this.endTime = this.$store.state.lPastMonthEnd;
          break;
      }
      this.showSort = false;

      this.init();
    },
    changeTab(tab) {
      this.pageNum = 0;
      this.isDisabled = false;
      this.eCouponList = [];
      this.expiredCouponList = [];
      this.tab = tab;
      if (tab == 1) {
        this.init();
      } else {
        this.getExpired();
      }
    },
    loadData(e) {
      this.pageNum++;
      this.init(e);
    },
    goDetail(item) {
      this.$store.state.form.userCouponDetail = item;
      this.$router.push({
        path: "/couponUserDetail",
      });
    },
  },
  ionViewWillEnter() {
    this.eCouponList = [];
    this.expiredCouponList = [];
    this.pageNum = 0;
    this.isDisabled = false;
    this.init();
    this.$store.state.exitApp = false;

    this.sendGAPageView("eCoupon History");
  },
};
</script>
